// Body
//
// Settings for the `<body>` element.

$body-bg:       hsl(215, 50%, 55%);
$body-color:    $white;

// Links
//
// Style anchor elements.

$link-color:            $white;
$link-hover-color:      $white;
$link-hover-decoration: none;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-weight-base: $font-weight-light;
