@import "bootstrap";

// Base
a {
  border-bottom: 2px solid hsl(215, 50%, 70%);

  &:hover {
    border-color: hsl(45, 98%, 77%);
  }
}

hr {
  border-width: 2px;
}

// Scaffolding
#words {}
#sounds {}
#projects {}
#work {}
#post {}


// Navigation
#navigation {
  padding-top: $navbar-padding-y;
  padding-bottom: $navbar-padding-y;
  margin-top: $navbar-padding-x;
  margin-bottom: $navbar-padding-x;

  .nav {
    > a {
      display: inline-block;
      color: hsl(215, 50%, 70%);
      border-color: transparent;

      &:hover {
        color: #fff;
        border-color: hsl(45, 98%, 77%);
      }
    }

    a.active {
      color: $link-color;
      border-color: hsl(45, 98%, 77%);
    }
  }
}

#post {
  #navigation {
    .nav > a {
      color: $gray-600;
      &:hover { color: $gray-800; }
    }
  }
}


// Post

.post-content {
  padding-top: $grid-gutter-width * 2;
  padding-bottom: $grid-gutter-width * 2;
  color: $gray-700;

  .post-year {
    display: block;
    text-align: center;
    font-weight: $font-weight-light;
  }

  .post-title {
    margin-bottom: $grid-gutter-width * 3;
    text-align: center;
    font-weight: 500;
  }

  .post-body {
    font-size: 16px;
    line-height: 1.7;

    a {
      color: $gray-700;
    }

    > p {
      margin-bottom: $spacer * 1.5;

      &:first-child {
        font-size: 20px;
        font-weight: 100;
        margin-bottom: $spacer * 3;
      }
    }

    img {
      @include img-fluid;
      margin: 0 0 $spacer * 1.5;
    }

    > hr {
      margin-left: 40% !important;
      margin-right: 40% !important;
    }

    figure {
      margin-bottom: $spacer * 1.5;

      figcaption {
        padding: ($spacer * .75) ($spacer * 1.5);
        font-size: $font-size-base;
        line-height: $spacer * 1.5;
        background-color: $gray-100;
      }

      p { margin: 0; }
      img { margin: 0; }
    }

    .post-journal-notes {
      margin-top: $spacer * 6;
      margin-bottom: $spacer * 6;
      padding: $spacer * 1.5;
      font-size: $h4-font-size;
      font-weight: 100;
      border: 4px solid $gray-100;
      text-align: justify;
    }
  }

  .post-footer {
    margin-top: $spacer * 6;
    padding: $spacer;
    font-weight: 100;
    border: 2px solid $gray-400;
    text-align: center;
    color: $gray-800;
  }
}


// Pages
//****************************************

// Words
.post-list {
  padding-top: $spacer * 1.5;
  margin-top: $spacer * 1.5;
}

.post {
  &.has-year {
    margin-top: $spacer * 1.5;
  }

  .post-year {
    margin-top: $spacer * .75;
    color: hsl(215, 50%, 85%);
    font-weight: $font-weight-light;
    font-size: $font-size-base;
  }

  .post-title {
    margin-top: $spacer * .75;
    font-weight: $font-weight-normal;
    font-size: $font-size-base;
  }
}

.project {
  &.has-year {
    margin-top: $spacer * 1.5;
  }

  .project-year {
    margin-top: $spacer * .75;
    color: hsl(215, 50%, 85%);
    font-weight: $font-weight-light;
    font-size: $font-size-base;
  }

  .project-title {
    margin-top: $spacer * .75;
    font-weight: $font-weight-normal;
    font-size: $font-size-base;
  }

  .project-description {
    color: hsl(215, 50%, 95%);
    font-weight: $font-weight-light;
  }
}

// Sounds
.sound-list {
  padding-top: $spacer * 1.5;
  margin-top: $spacer * 1.5;
}

.sound {
  margin-bottom: $spacer * 1.5;
  padding-bottom: $spacer * 1.5;

  &.has-year {
    margin-top: $spacer * 1.5;
  }

  .sound-year {
    margin-top: $spacer * .75;
    color: hsl(215, 50%, 85%);
    font-weight: $font-weight-light;
    font-size: $font-size-base;

    // @media (max-width: $screen-sm-max) {
    //   padding-left: 40px;
    // }
  }

  .sound-media {
    &:hover, &:focus {
      opacity: 0.5;
    }
  }

  .sound-title {
    margin-top: 0;
    padding-left: 40px;
    font-weight: $font-weight-normal;
    font-size: $font-size-base;
  }

  .sound-tracks {
    color: hsl(215, 50%, 95%);
    font-weight: $font-weight-light;
  }
}
